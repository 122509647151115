/**
 * Liste des pages accessibles depuis le vue home de l'ACS
 */
import { defines } from "../../router/defines.js";
import { hasOneOfRole } from "../../service/role_service.js";


export const tableListAcs = [
  {
    // Les rôles des services
    name: "Services",
    route: defines.acsservices.view.path,
    roles: [ defines.acsservices.view.roles, ], 
  },
  {
    // Les rôles des fonctions
    name: "Fonctions",
    route: defines.acsfunctions.view.path, 
    roles: [ defines.acsfunctions.view.roles, ],
  },
  {
    // Les rôles par collaborateurs (choix du collaborateur first)
    name: "Collaborateurs",
    route: defines.acsusers.view.path,
    roles: [ defines.acsusers.view.roles, ],
  },
  {
    // Les rôles de tous les clients
    name: "Clients",
    route: defines.acscustomerroles.view.path,
    roles: [ defines.acscustomerroles.view.roles, ],
  },
  {
    // Les rôles de tous les collaborateurs ayant de rôles spécifiques
    name: "Rôles spécifiques collaborateurs",
    route: defines.acsusersroles.view.path,
    roles: [ defines.acsusersroles.view.roles, ],
  },
]


/**
 * Retourne la liste des pages consultables pour ACS 
 * en fonction des droits applicatifs de l'utilisateur
 */
export function getTableList() {
  let table = [];

  // Parcours les tables a affiché
  for (let item of tableListAcs) {
    // Vérifie que l'utilisateur as le droit d'accéder à cette page.
    if (hasOneOfRole(item.roles)) {
      table.push(item);
    }
  }

  return table;
}