var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center", attrs: { flat: "", tile: "" } },
        [
          _c("Workflow", {
            attrs: {
              width: "600",
              height: "180",
              steps: _vm.workflowSteps,
              currentStep: _vm.workflowIndex,
              labelWidth: 200,
              lineWidth: 140,
              title: "",
            },
          }),
        ],
        1
      ),
      _c("v-row", { staticClass: "mb-12", attrs: { justify: "center" } }, [
        _c("h1", [_vm._v("Gestion des rôles ACS")]),
      ]),
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { xl: "6", lg: "6", md: "6", sm: "6", xs: "6" } },
            [
              _c(
                "v-row",
                { attrs: { justify: "center", "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    _vm._l(_vm.listOfItems, function (item) {
                      return _c(
                        "div",
                        { key: item.name },
                        [
                          _c(
                            "v-card",
                            {
                              attrs: {
                                tile: "",
                                elevation: "0",
                                to: item.route,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "my-1 text-body-1 font-weight-regular",
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                            ]
                          ),
                          _c("v-divider"),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }